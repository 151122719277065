import React from "react";
import Section from "../Section";
import "./index.css";

export function BookCover(props) {
    return (
        <div className={props.className} style={{ marginRight: props.marginRight ? `${props.marginRight}em` : 0 }}>
            <a href={props.url} target="_blank"><img className="bookCoverImg" src={`img/books/${props.title.replace(/\s/g, "").toLowerCase()}.jpg`} alt={props.title} style={props.height ? { height: props.height } : {}} /></a>
        </div>
    );
}

export function BookSpin(props) {
    return (
        <div>{props.title}</div>
    )
}

export function BookThreeD(props) {
    return (
        <div className={props.className} >
            <div className="cube">
                <div className="cube__face cube__face--front"
                style={{backgroundImage: `url(img/books/${props.title.replace(/\s/g, "").toLowerCase()}.jpg)`, backgroundSize: "cover"}}>
                
                </div>
                <div className="cube__face cube__face--right"></div>
            </div>
        </div>
    )
}
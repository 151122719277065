import React, { useState } from "react";
import "./index.css";
import axios from "axios";


export default function Bonus() {
    const [formState, setFormState] = useState({ name: "", email: "", dkc: false, nd: false, al: false, cc: false, argd: false, list: true, titlesSet: false});
    const [error, setError] = useState(false);
    const [sending, setSending] = useState(false);

    function handleTextInput(event) {
        setFormState({ ...formState, [event.target.name]: event.target.value });
    }

    function handleCheckbox(event) {
        setFormState({ ...formState, [event.target.name]: !formState[event.target.name] });
    }

    async function handleSubmit() {
        if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(formState.email) || formState.name.length < 2) {
            setError(true);
            return;
        } else {
            // get book links from bookfunnel for each
            

            console.log(formState);
            const listBody = {
                email: formState.email,
                name: formState.name,
                newEpisodes: formState.list, 
                bsfiBooks: formState.list, 
                otherBooks: formState.list,
                dkc: formState.dkc,
                nd: formState.nd,
                al: formState.al,
                cc: formState.cc,
                argd: formState.argd
            }
            setSending(true);
            axios.post("/api/list", listBody)
            .then(response => {
                console.log(response);
            })
        }

    }

    function setTitles() {
        setFormState({...formState, titlesSet: true})
    }

    function reset() {
        setFormState({ name: "", email: "", dkc: false, nd: false, al: false, list: true, titlesSet: false});
        setError(false);
        setSending(false);
    }

    return (
        <div className="bonus masthead">
            <h1 className="typewriter" onClick={reset}>Bonus</h1>
            <div className="container" style={{color: "white"}}>
                <form className="row">
                    <div className="col-lg-8 mx-auto">
                        <div className="form-group">
                            {error && formState.name.length < 2 ? <label style={{color: "pink"}} htmlFor="name">Name (enter your name)</label> : 
                          <label htmlFor="name">Name</label>
                            }
                          <input value={formState.name} type="text" minLength={2} onChange={handleTextInput}
                            className="form-control" name="name" id="name" aria-describedby="helpId" placeholder="" />
                        </div>
                        <div className="form-group">
                          {error && !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(formState.email) ? 
                          <label style={{color: "pink"}} htmlFor="email">Email Address (enter a valid email address)</label>
                          :
                          <label htmlFor="email">Email Address</label>
                        }
                          <input value={formState.email} type="email" onChange={handleTextInput}
                            className="form-control" name="email" id="email" aria-describedby="helpId" placeholder="" />
                        </div>
                        {!formState.titlesSet ? <> 
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="checkbox" name="dkc" id="dkc" value="dkc" onChange={handleCheckbox} checked={formState.dkc} />
                            <label className="form-check-label" htmlFor="dkc">The Dead Kids Club</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="checkbox" name="nd" id="nd" value="nd" onChange={handleCheckbox} checked={formState.nd} />
                            <label className="form-check-label" htmlFor="nd">Near Death</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="checkbox" id="al" value="al" name="al" onChange={handleCheckbox} checked={formState.al} />
                            <label className="form-check-label" htmlFor="al">After Life</label>
                        </div><br />
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="checkbox" id="cc" value="cc" name="cc" onChange={handleCheckbox} checked={formState.cc} />
                            <label className="form-check-label" htmlFor="al">The Charlatan's Conundrum</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="checkbox" id="argd" value="argd" name="argd" onChange={handleCheckbox} checked={formState.argd} />
                            <label className="form-check-label" htmlFor="al">A Really Good Day</label>
                        </div><br /><br />
                        </> : <>
                            <div>
                                <img style={{display: formState.dkc ? "inline-block" : "none"}} className="formBookCover" src="/img/books/thedeadkidsclub.jpg" alt="The Dead Kids Club" />
                                <img style={{display: formState.nd ? "inline-block" : "none"}} className="formBookCover" src="/img/books/neardeath.jpg" alt="The Dead Kids Club" />
                                <img style={{display: formState.al ? "inline-block" : "none"}} className="formBookCover" src="/img/books/afterlife.jpg" alt="The Dead Kids Club" />
                                <img style={{display: formState.cc ? "inline-block" : "none"}} className="formBookCover" src="/img/books/thecharlatansconundrum.jpg" alt="The Dead Kids Club" />
                                <img style={{display: formState.argd ? "inline-block" : "none"}} className="formBookCover" src="/img/books/areallygoodday.jpg" alt="The Dead Kids Club" />
                            </div>
                        </>}
                        {/* <div className="form-check form-check-inline">
                            <input className="form-check-input" type="hidden" name="list" id="list" value={formState.list} />
                            <label className="form-check-label" htmlFor="list">Add me to mailing list</label>
                        </div><br /><br /> */}
                        <button style={{display: formState.titlesSet ? "none" : "block"}} type="button" className="btn btn-primary" onClick={setTitles}>Set Titles</button>
                        <button style={{display: formState.titlesSet ? "block" : "none"}} type="button" className="btn btn-primary" onClick={handleSubmit}>{sending ? "Sending Books" : "Send Books"}</button>
                    </div>
                </form>
            </div>
        </div>
    )
}
import React from "react";
import Section from "../../components/Section";
import "./index.css";
import axios from "axios";
import parse from "html-react-parser";
import TextFit from "@gmurph91/react-textfit";

class Podcast extends React.Component {
    constructor(props) {
        super(props);
        this.state = { episodes: [], logoUrl: "" };
    }

    componentDidMount() {
        this.getRssFeed();
    }

    getRssFeed() {
        axios.get("/api/rssxml")
            .then(({ data }) => {
                console.log(data);
                this.setState({ episodes: data.items.slice(0, 6) });

            })
    }

    render() {
        const { props } = this;

        return (
            <Section {...props}>
                <div className="container" style={{ height: "100%" }}>
                    <h1 className="fiction">PODCAST</h1>
                    <div className="d-flex flex-column flex-lg-row justify-content-center align-items-center">
                        <div className="bedtime mx-2 text-center">Bedtime Stories</div>
                        <div className="insomniac mx-2 text-center">FOR INSOMNIACS</div>
                    </div>
                    <h3 className="letme">Let me tell you a story...</h3>
                    
                    
                    <p className="text-light text-center">Subscribe on your favorite <i className="fad fa-podcast"></i> podcast app or <i className="fab fa-audible"></i> Audible for free.</p>
                    <div className="d-flex flex-wrap justify-content-center app-links">
                        <a href="https://podcasts.apple.com/us/podcast/written-by-rich-hosek/id1603374090" target="_black"><button id="apple" className="btn btn-lg btn-apple"><i className="far fa-podcast"></i> Apple</button></a>
                        <a href="https://podcasts.google.com/feed/aHR0cHM6Ly93cml0dGVuYnlyaWNoaG9zZWsuY2FzdG9zLmNvbS9mZWVk" target="_black"><button id="google" className="btn btn-lg btn-light"><img width="20" alt="Google Podcasts icon" src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/25/Google_Podcasts_icon.svg/32px-Google_Podcasts_icon.svg.png" /> Google</button></a>
                        <a href="https://open.spotify.com/show/0oC2s0VBuI3jpqq9LmafJm" target="_blank"><button id="spotify" className="btn btn-lg btn-dark"><i className="fab fa-spotify"></i> Spotify</button></a>
                        
                        <a href="https://goodpods.com/podcasts/bedtime-stories-for-insomniacs-written-by-rich-hosek-212131"><img className="m-2" src="https://storage.googleapis.com/goodpods-images-bucket/badges/generic-badge-1.svg" alt="listen to bedtime stories for insomniacs - written by rich hosek podcast on goodpods" style={{width: "225px"}} /></a>
                        <a href="https://pca.st/2lka7dxe" target="_blank"><button id="pocketcasts" className="btn btn-lg btn-dark"><img width="20" src="/img/pocketcasts.png" /> Pocket Casts</button></a>
                        <a href="https://music.amazon.com/podcasts/7c4a33f0-023d-4d19-a071-b1b46e15a40d/written-by-rich-hosek" target="_blank"><button id="amazon" className="btn btn-lg btn-warning"><i className="fab fa-amazon"></i> Amazon</button></a>
                        <a href="https://www.audible.com/pd/Written-by-Rich-Hosek-Podcast/B08K57MZ6X" target="_blank"><button id="audible" className="btn btn-lg btn-light"><i className="fab fa-audible"></i> Audible</button></a>
                        <a href="https://www.iheart.com/podcast/269-written-by-rich-hosek-91216195/" target="_blank"><button id="iheartradio" className="btn btn-lg btn-danger">I <i className="fas fa-heart"></i> Radio</button></a>
                    </div>
                    <h2>Latest Episodes</h2>
                    <div className="d-flex flex-wrap justify-content-between">
                        {this.state.episodes.map(episode => (
                            <div className="card position-relative" key={episode.title}>

                                
                                            <img src={episode.thumbnail} alt="Avatar" />
                                            <h5 className="card-title">
                                                <TextFit max={32} mode="single">{episode.title}</TextFit>
                                            </h5>
                                        <div className="description" style={{height: 150, width: 300}}>
                                            <TextFit mode="multi" style={{height: "134px"}}>
                                                {parse(episode.content.match(/<p>(.*)<\/p>/g) ? episode.content.match(/<p>(.*)<\/p>/g)[0].replace("<p>", "").replace("</p>", "") : episode.content)}
                                                </TextFit>
                                        </div>
                                                <a href={episode.link} target="_blank" className="btn btn-dark"><i className="fas fa-headphones-alt"></i> Listen Online</a>
                                
                                {/* <div className="flip-card">
                                        <div className="flip-card-inner">
                                            <div className="flip-card-front position-relative">
                                                <img className="card-img-top" src={episode.thumbnail} alt="episode.title" />
                                                <h5 className="card-title">{episode.title}</h5>
                                                <a href={episode.link} target="_blank" className="btn btn-dark"><i className="fas fa-headphones-alt"></i> Listen Online</a>
                                            </div>
                                            <div className="flip-card-back">
                                            {ReactHtmlParser(episode.content)}
                                            </div>
                                        </div>
                                    </div> */}


                            </div>
                        ))}
                    </div>
                </div>

            </Section>
        );
    }
}

export default Podcast;
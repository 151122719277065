import React, { Component } from "react";
import logo from "./logo.svg";
import "./App.css";
import axios from "axios";
import Header from "./pages/Header";
import Latest from "./pages/Latest";
import Reviews from "./pages/Reviews";
import Nav from "./components/Nav";
import Books from "./pages/Books";
import Podcast from "./pages/Podcast";
import Television from "./pages/Television";
import Links from "./pages/Links";
import Footer from "./components/Footer";

class App extends Component {
  state = {
    testValue: ""
  }

  componentDidMount() {
    document.querySelector("html").scrollTop = 0;
  }

  render() {
    const ref = React.createRef();
    return (
      <>
        {/* <!-- Navigation --> */}
        <Nav />
        <Header id="home" className="masthead"  />
        {/* <Latest id="latest" className="about-section text-center" /> */}
        <Books id="books" className="projects-section bg-light" />
        <Podcast id="podcast" className="projects-section bg-light" />
        <Reviews id="reviews" className="projects-section bg-light" />
        <Television id="television" className="projects-section bg-dark" />
        <Links id="links" className="signup-section" />
        <Footer />
      </>
    );
  }
}

export default App;

import React from "react";
import {useParams} from "react-router"

function Error() {
    const params = useParams();
    
        return (
            <div className="error">
                <h1>ERROR</h1>
                <pre>{JSON.stringify(params, null, 4)}</pre>                
            </div>
        );
    }

export default Error;
import React from "react";
import Section from "../../components/Section";
import { BookCover } from "../../components/Book";
import "./index.css"

function Books(props) {
    return <Section {...props}>
        <div className="container" style={{ height: "100%" }}>
            <h1 className="typewriter heading-dark">BOOKS</h1>
            <div className="book_shelf">
                <div className="book">
                    <BookCover title="Beyond the Levee" height="30vh" url="https://www.amazon.com/Beyond-Levee-Other-Ghostly-Tales/dp/B08HGNS5KP" />
                </div>
                <div className="book">
                    <BookCover title="Near Death" height="30vh" url="https://raneyanddaye.com/book/neardeath" />
                </div>
                <div className="book">
                    <BookCover title="The Dead Kids Club" height="30vh" url="https://www.amazon.com/Dead-Kids-Files-Eddie-Horne/dp/1953566022" />
                </div>
                <div className="book">
                    <BookCover title="After Life" height="30vh" url="https://raneyanddaye.com/book/afterlife" />
                </div>
                <div className="book coming-soon">
                    <BookCover title="The Tenth Ride" height="30vh" />
                </div>
            </div>
        </div>
    </Section>
}

export default Books;
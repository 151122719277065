import React, {useState, useEffect} from "react";
import ScrollIntoView from 'react-scroll-into-view'
import Section from "../../components/Section";
import "./index.css";
import {BookCover, BookThreeD} from "../../components/Book"
import Books from "../Books";
import axios from "axios";
import parse from "html-react-parser";
import TextFit from "@gmurph91/react-textfit";

const Top = React.forwardRef((props, ref) => {
    const [episodes, setEpisodes] = useState([])
    useEffect(() => {
        axios.get("/api/rssxml")
            .then(({ data }) => {
                console.log(data);
                setEpisodes(data.items.slice(0, 2));

            })
    }, []);
    return (
        <Section className="section-top" {...props}>
            <div className="container d-flex h-100 align-items-center">
                <div className="this-is-rich"><img className="signature" src="img/signature.RichHosek.png" alt="Rich Hosek" /><img className="floating-head" src="img/floatinghead.png" alt="Floating Head" /></div>
                <div style={{zIndex: 1000}} className="d-flex flex-column justify-content-center w-100 column">
                    <h1 className="typewriter mx-auto my-0 text-uppercase row">Rich Hosek</h1>
                    <h2 className="typewriter text-white-50 mx-auto mt-2 mb-5 row">Writer</h2>
                    
                    <div className="row align-content-center flex-column mb-5">
                        <h3 className="text-center">Let me tell you a story...</h3>
                        <div id="podcast-preview" className="d-flex flex-column flex-md-row">
                            <img className="flex-grow-0 d-none d-lg-inline" id="podcast-logo" src="/img/podcastlogo.png" />
                            <div id="podcast-desc" className="text-center">
                            <div>A fresh, new, original story every week, plus serialized audio versions of my novels<br />on the <span className="font-weight-bold">Bedtime Stories for Insomniacs</span> fiction podcast</div>
                            <div className="d-flex flex-column flex-md-row mt-2" id="episodes">
                                <ScrollIntoView selector="#podcast"><a id="subscribe" className="btn btn-primary btn-sm js-scroll-trigger mt-2"> Subscribe <br className="d-md-inline d-none" /> on all <br className="d-md-inline d-none" /> podcast <br className="d-md-inline d-none"/> apps </a></ScrollIntoView>
                                {episodes.map(episode => (
                                    <a href={episode.link} className="episode-preview d-flex">
                                        <img src={episode.thumbnail} />
                                        <div className="flex-grow-1">
                                            <h6>{parse(episode.title.replace(" - Part", "<br/>Part"))}</h6>
                                            <a style={{padding: ".25em", color: "white", margin: ".5em"}} className="btn btn-light btn-sm text-dark">Listen Online</a>
                                        </div>
                                    </a>
                                ))}
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="flex bookInFocus col" id="onsale">
                            <div className="order-2 order-md-1 bookInfo flex flex-column justify-content-between">
                                <div>
                                    <span className="font-weight-bold bookInfoHeading">ON SALE NOW</span>
                                    <p><em>After Life</em> is the second book in the  <a href="http://raneydayeinvestigations.com"><strong>Raney/Daye Investigations</strong></a> paranormal mystery book series. A young befriends the ghost of a woman who died in a bank robbery fifteen years earlier and has a million dollar secret some people are willing to kill for.</p>
                                    {/* <p>Paperack of Kindle</p> */}
                                </div>
                                <a href="https://www.amazon.com/Dead-Kids-Files-Eddie-Horne/dp/1953566022">
                                    <img src="/img/buttons/amazon.png" className="w-50"></img>
                                </a>
                            </div> 
                            <BookCover className="order-1 order-md-2 scene"  title="After Life" url="https://raneyanddaye.com/book/afterlife"></BookCover>
                            {/* <BookCover className="order-1 order-md-2" title="The Dead Kids Club" url="https://www.amazon.com/Dead-Kids-Files-Eddie-Horne/dp/1953566022" /> */}
                        </div>
                        <div className="bookInFocus col" id="comingsoon">
                            {/* <BookCover title="Near Death" url="https://www.amazon.com/Near-Death-Raney-Daye-Investigation/dp/1953566006/" /> */}
                            <BookCover className="scene"  title="The Dead Kids Club" url="https://www.amazon.com/Dead-Kids-Files-Eddie-Horne/dp/1953566022"></BookCover>
                            <div className="bookInfo">
                                <div>
                                    <span className="font-weight-bold bookInfoHeading">PAGE-TURNING THRILLER</span>
                                    <p><em>The Dead Kids Club</em> is an intense thriller about parents struggling to go on after the death of their child by the hand of an unrepentent and unpunished drunk driver. The thing is, once we set aside our better angels to let loose our inner demons, there's no turning back.</p>
                                    {/* <p>Currently available in Kindle eBook, trade paperack and Audiobook</p> */}
                                </div>
                                <a href="https://www.amazon.com/Near-Death-Raney-Daye-Investigation/dp/1953566006/">
                                    <img src="/img/buttons/amazon.png" className="w-50"></img>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="cta row"><ScrollIntoView className="" selector="#books"><a  className="btn btn-primary js-scroll-trigger mt-4">All my books</a></ScrollIntoView><ScrollIntoView className="" selector="#links"><a  className="btn btn-primary js-scroll-trigger mt-4">All my links</a></ScrollIntoView></div>
                    
                </div>
            </div>
        </Section>
    );
});

export default Top;
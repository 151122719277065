import React, { useState } from "react";
import Section from "../../components/Section";
import { BookCover } from "../../components/Book";
import { Review, ReviewList } from "../../components/Reviews";
import "./index.css"

function Reviews(props) {

    const [book, setBook] = useState("Near Death");
    const [review, setReview] = useState("dkc-vd");
    const [mobileShow, setMobileShow] = useState(false);

    const reviews = {
        "Near Death": [],
        "The Dead Kids Club": []
    }

    function showReview() {
        switch (review) {
            case "dkc-gm":
                return <p><img className="fiveStarBadge" src="/img/5star-flat-web.png" /><em>The Dead Kids Club</em> by Rich Hosek is a story of a couple that loses
                their son in a tragic accident. After dropping Nick off for school, his
                father is shocked as a drunk driver runs the kid over. The pain the
                father and his ex-wife, Rebecca, experience is searing. They put all
                their hopes in the justice system, believing the rookie Assistant
                District Attorney’s confidence that it is an open and shut case. But
                they are stunned when Anthony Vitali, the killer, walks out of the
                courtroom a free man. Rebecca and her ex-husband can’t bear the
                pain of seeing the killer of their son go free, so they set out to do
                the one thing possible: get not only justice but revenge. Can they
                find peace after murdering Anthony and can they get away with it?<br /><br />
                Rich Hosek has written a beautiful story that explores the darkness
                of the human heart. The way the author writes about the
                experience of loss is wonderful and real, and it is easy for the
                reader to imagine the pain the protagonist and his ex-wife go through.
                This pain of loss drives them and it defines motivation in this
                narrative. The prose is lofty and the author captures the emotions
                of the characters in descriptions that are clear and unmistakable.
                <em>The Dead Kids Club</em> is effectively written in the first-person
                narrative voice, allowing the perspective of the protagonist to come
                out neatly through the story. While the theme is dark, dealing with
                revenge, the author does a great job of infusing humanity into the
                characters. They reflect what most people can do when deeply hurt.
                This is a story that is emotionally rich and that features compelling
                characters, and the author’s expert handling of the plot is one of
                the things I loved most about this novel.</p>
            case "dkc-ss":
                return <p><img className="fiveStarBadge" src="/img/5star-flat-web.png" />When a divorced couple's young son is killed by a drunk driver, they
                take back their lives by exacting revenge in the suspenseful thriller,
                <em>The Dead Kids Club</em> by Rich Hosek. Richard and Rebecca had been
                divorced for three years when the death of their son, Nick, brings
                them back together in an unexpected way. After the trial, where the
                driver is acquitted, Richard and Rebecca start making plans. First,
                they move back in together, next they join a support group, then
                they murder the man who killed their son. While the peaceful
                effects of getting revenge for Nick's untimely death are still
                satisfying, Rebecca insists they help other support group members
                get closure as well. Unfortunately, due to the unusual coincidences
                of child killers meeting their demise in the area, they attract the
                attention of a local mobster and a journalist. This turn of events
                convinces Richard that it is time to quit before they are caught
                either by the mob or the law. But is it already too late? Will they
                soon be swimming with the fishes or going to jail?<br /><br />
                    <em>The Dead Kids Club</em> by Rich Hosek is a unique and entertaining
thriller where the serial killer is the good guy. Filled with
heartrending scenes and callous killers, it is rather hard not to side
with the vigilantes. I got caught up in the drama of the first scene
and was so captivated I couldn't put the book down until I reached
the scintillating conclusion. It is a fascinating tale with an intense
plot, interesting characters, and an engaging storyline that I will not
soon forget. It is a sensational book that will delight and entertain
everyone who is a fan of suspense and thrillers. However, there are
a few intimate scenes that, although not graphic, are more suitable
for mature readers.</p>
            case "dkc-jm":
                return <p><img className="fiveStarBadge" src="/img/5star-flat-web.png" /><em>The Dead Kids Club</em> by Rich Hosek is a thriller that revolves around
                two parents, Rebecca and Richard Argent, who are grappling with
                the emotional aftermath of their young son Nick's death. Hit and
                killed by a drunk driver in front of the horrified eyes of his father
                and other schoolchildren and parents, Nick's death goes
                unpunished as Anthony Vitali, the son of a notorious mob boss,
                walks away thanks to his father's money and connections. Rebecca
                and Richard are destroyed and their lives come crashing down
                around them, until a plan is hatched that circumvents the final
                stage of grief, acceptance, in favor of one that feels a lot more
                satisfying: revenge.<br /><br />
                Rich Hosek writes with a straightforward voice that doesn't dance
around seemingly impossible situations that give rise to rash
actions in <em>The Dead Kids Club</em>. There is a very real, incredibly raw
human element woven into the progression of Rebecca and
Richard's anguish, one where the desire for inflicting suffering on
those who created it in yourself overcomes what most would
consider to be better judgment. Where Hosek shines is in making it
so a reader roots for the blood of Vitali with the same fervor as the
Argents' quest to spill it. From about the halfway mark in the story,
this camaraderie between reader and protagonists starts to shift
into growing tinges of doubt as their vigilantism spreads and the
evolution of their private and public lives, their thirst for more, and
the duplicitous sorry-not-sorry relief that overwhelms the Argents'
support group of parents who have also lost their children. All
combined, Hosek leads the character-driven plot with worthy anti-
heroes and a satisfying series of wrongs dripping with enough
moral ambiguity to make them feel maybe, possibly, just a little
right.</p>
            case "dkc-vd":
                return <p><img className="fiveStarBadge" src="/img/5star-flat-web.png" /><em>The Dead Kids Club</em> is an intense thriller by Rich Hosek. A divorced
                father narrates how the life of his young son ended too soon. As he
                drops his son Nick at school, the boy grabs his backpack and rushes
                to join the other first graders funneling into the school. But Nick
                forgot his lunch bag and rushes back to get it. Then it happened: An
                unrepentant drunk driver gets away with killing Nick. This tragedy
                becomes a catalyst for the narrator to get back with his wife,
                Rebecca, and they join forces to exact revenge against Anthony
                Vitali, the driver who killed Nick. Being the son of a major player in
                the mob, Vitali is a force to contend with. As the husband and wife
                team plots revenge, they must set the stage to play the role that
                will give them the one thing that they failed to get from police and
                the courts: justice. Better yet, revenge.<br /><br />
                In <em>The Dead Kids Club</em>, Hosek gives generous insight into the mind
                and actions of the protagonist as well as his wife, because grief
                from the loss of their son makes them vulnerable and it clouds their
                judgment—which makes for a reasonable element to plot murder.
                The question is, can they? And the fast-paced plotting makes
                finding out the answer worth tracking along the pages. You will
                enjoy witnessing how this husband and wife team carries out their
                plans, and how they work to get out of dangerous situations and
                deal with shady characters. Hosek has written a thriller that reads
                like a James Patterson novel that you can devour in an hour or two.
                With a balance between mayhem and drama, <em>The Dead Kids Club</em> is
                a brilliant and gripping novel that will give you an adrenaline rush.</p>
        }
    }

    function hideReview(event) {
        event.target.parentElement.scrollTop = 0;

        setMobileShow(false);
    }

    return <Section {...props}>
        <div className="container" style={{ height: "100%" }}>
            <h1 className="typewriter heading-dark">REVIEWS</h1>
            <div className="d-flex mb-3">
                {/* <BookCover title="Near Death" height="15vh" marginRight={1} /> */}
                <BookCover title="The Dead Kids Club" height="15vh" marginRight={1} />
            </div>
            <div className="row ">
                <div className="col col-md-3 reviewList font-weight-bold">
                    <div className={"reviewLabel" + ((review == "dkc-vd") ? " selected" : "")} onClick={() => { setReview("dkc-vd"); setMobileShow(true); }}>
                        <div className="text-center">⭐⭐⭐⭐⭐</div>
                        <div className="text-center text-uppercase">Readers' Favorite</div>
                        <div className="text-right">&mdash;Vincent Dublado</div>
                    </div>
                    <div className={"reviewLabel" + ((review == "dkc-ss") ? " selected" : "")} onClick={() => { setReview("dkc-ss"); setMobileShow(true); }}>
                        <div className="text-center">⭐⭐⭐⭐⭐</div>
                        <div className="text-center text-uppercase">Readers' Favorite</div>
                        <div className="text-right">&mdash;Susan Sewell</div>
                    </div>
                    <div className={"reviewLabel" + ((review == "dkc-gm") ? " selected" : "")} onClick={() => { setReview("dkc-gm"); setMobileShow(true); }}>
                        <div className="text-center">⭐⭐⭐⭐⭐</div>
                        <div className="text-center text-uppercase">Readers' Favorite</div>
                        <div className="text-right">&mdash;Grace Masso</div>
                    </div>
                    <div className={"reviewLabel" + ((review == "dkc-jm") ? " selected" : "")} onClick={() => { setReview("dkc-jm"); setMobileShow(true); }}>
                        <div className="text-center">⭐⭐⭐⭐⭐</div>
                        <div className="text-center text-uppercase">Readers' Favorite</div>
                        <div className="text-right">&mdash;Jamie Michele</div>
                    </div>
                </div>
                <div className={"col col-md-9 reviewDetail mobile-override " + (mobileShow ? "mobile-show" : "mobile-hide")}>
                    {showReview()}
                    <button className="btn w-100" onClick={hideReview}>CLOSE</button>
                </div>

            </div>
        </div>
    </Section>
}

export default Reviews;
import React, { useState } from "react";
import Section from "../../components/Section";
import axios from "axios";
import "./index.css"

function Links(props) {

    const [email, setEmail] = useState("");
    const [showSuccess, setShowSuccess] = useState(false);

    const submitEmail = (event) => {
        event.preventDefault();
        console.log("Email Address:", email);
        axios.post(`/api/email/${email}`)
            .then(result => {
                console.log(result)
                setEmail("");
                setShowSuccess(true);
            })
            .catch(console.error)
    }

    return (
        <Section {...props}>
            <div className="container" style={{ height: "100vh" }}>
                {/* TODO: Fix Mailing List signup */}
                {/* <div className="row">
                    <div className="col-md-10 col-lg-8 mx-auto text-center">

                        <i className="far fa-paper-plane fa-2x mb-2 text-white"></i>
                        {showSuccess ? <h2 className="typewriter mb-5" style={{ color: "lightgreen" }}>Thanks for Subscribing!</h2> : <h2 className=" typewriter text-white mb-5">Subscribe to receive updates!</h2>}

                        <form className="form-inline d-flex" onSubmit={submitEmail} >
                            <input type="email" className="form-control flex-fill mr-0 mr-sm-2 mb-3 mb-sm-0" name="email" id="email" placeholder="Enter email address..." value={email} onChange={event => setEmail(event.target.value)} />
                            <button type="submit" className="btn btn-primary mx-auto">Subscribe</button>
                        </form>

                    </div>
                </div> */}

                <div className="row mt-4" style={{fontSize: "1.5em"}}>
                    <a href={`https://bedtimestories.studio/snoozeletter/${email}`} target="_blank" className="col-12 col-md-8 offset-md-2 mb-4 bg-light link">
                        <div className="text-center"><input onClick={(event) => {event.preventDefault(); event.stopPropagation();}} value={email} onChange={(event) => setEmail(event.target.value)} placeholder="enter your email address" /><br />Sign up for the <i className="fas fa-mail-bulk"></i> Insomniacs Snoozeletter</div>
                    </a>
                    <a href="https://bedtimestories.studio" target="_blank" className="col-12 col-md-8 offset-md-2 mb-4 bg-light link">
                        <div className="text-center">Listen to weekly  <i className="fas fa-headphones"></i> audio stories and audiobooks</div>
                    </a>
                    <a href="https://bedtimestories.studio/subscribe" target="_blank" className="col-12 col-md-8 offset-md-2 mb-4 bg-light link">
                        <div className="text-center">Subscribe on your favorite <i className="far fa-podcast"></i> podcast app</div>
                    </a>
                    <a href="https://twitter.com/RichHosek" target="_blank" className="col-12 col-md-8 offset-md-2 mb-4 bg-light link">
                        <div className="text-center">Follow me on Twitter <i className="fab fa-twitter-square"></i> @RichHosek</div>
                    </a>
                    <a href="https://facebook.com/WrittenByRichHosek" target="_blank" className="col-12 col-md-8 offset-md-2 mb-4 bg-light link">
                        <div className="text-center">Like me on Facebook <i className="fab fa-facebook-square"></i> @Written by Rich Hosek</div>
                    </a>
                    <a href="https://medium.com/@RichHosek" target="_blank" className="col-12 col-md-8 offset-md-2 mb-4 bg-light link">
                        <div className="text-center">Read my stories on Medium <i className="fab fa-medium"></i> @RichHosek</div>
                    </a>
                    <a href="https://writtenbyrichhosek.locals.com" target="_blank" className="col-12 col-md-8 offset-md-2 mb-4 bg-light link">
                        <div className="text-center">Join me on SubStack <i className="fas fa-bookmark"></i> Insomniac's Snoozeletter</div>
                    </a>
                    <a href="https://www.amazon.com/Rich-Hosek/e/B08KG71LMV" target="_blank" className="col-12 col-md-8 offset-md-2 mb-4 bg-light link">
                        <div className="text-center">Buy my Books at <i className="fab fa-amazon"></i> Amazon </div>
                    </a>
                    <a href="https://www.audible.com/search?searchAuthor=Rich+Hosek" target="_blank" className="col-12 col-md-8 offset-md-2 mb-4 bg-light link">
                        <div className="text-center">and at <i className="fab fa-audible"></i> Audible</div>
                    </a>
                    <a href="https://www.imdb.com/name/nm0395923/" target="_blank" className="col-12 col-md-8 offset-md-2 mb-4 bg-light link">
                        <div className="text-center">My TV Credits at <i className="fab fa-imdb"></i> IMdB</div>
                    </a>
                </div>

                {/* <div className="row mt-4">

                    <a href="https://twitter.com/RichHosek" target="_blank" className="col-md-4 offset-md-2 mb-4">
                        <div className="card">
                            <div className="card-body text-center">
                                <i className="fab fa-twitter-square fa-3x"></i>
                                <h4 className="text-uppercase m-0">@RichHosek<br />&nbsp;</h4>
                                <p>Follow me on Twitter</p>
                            </div>
                        </div>
                    </a>

                    <a href="https://facebook.com/WrittenByRichHosek" target="_blank" className="col-md-4 mb-4">
                        <div className="card">
                            <div className="card-body text-center">
                                <i className="fab fa-facebook-square fa-3x"></i>
                                <h4 className="text-uppercase m-0">Written by<br />Rich Hosek</h4>
                                <p>Like me on Facebook</p>
                            </div>
                        </div>
                    </a>

                    <a href="https://medium.com/@RichHosek" target="_blank" className="col-md-4 offset-md-2 mb-4 ">
                        <div className="card">
                            <div className="card-body text-center">
                                <i className="fab fa-medium fa-3x"></i>
                                <h4 className="text-uppercase m-0">@RichHosek<br />&nbsp;</h4>
                                <p>Follow me on Medium</p>
                            </div>
                        </div>
                    </a>

                    <a href="https://writtenbyrichhosek.locals.com/" target="_blank" className="col-md-4 mb-4">
                        <div className="card">
                            <div className="card-body text-center">
                                <i className="fas fa-circle fa-3x"></i>
                                <h4 className="text-uppercase m-0">Written by<br />Rich Hosek</h4>
                                <p>Subscribe on Locals</p>
                            </div>
                        </div>
                    </a>
                </div> */}
            </div>
        </Section>
    )
}

export default Links;
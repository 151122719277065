import React from "react";
import ScrollIntoView from 'react-scroll-into-view'
import "./index.css"

const Nav = React.forwardRef((props, ref) => {

    function toggleMenu(event) {
        console.log(event.currentTarget);
        if (event.currentTarget.classList.contains("show")) {
            event.currentTarget.classList.remove("show")
        }
    }

    return (
        <nav className="navbar navbar-expand-lg navbar-light fixed-top" id="mainNav">
            <div className="container">
                <ScrollIntoView className="d-inline" selector="#home"><a className="typewriter navbar-brand js-scroll-trigger">RICH HOSEK</a></ScrollIntoView>
                <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                    Menu
          <i className="fas fa-bars"></i>
                </button>
                <div className="collapse navbar-collapse" id="navbarResponsive" onClick={toggleMenu}>
                    <ul className="navbar-nav ml-auto">
                        {/* <li className="nav-item">
                            <ScrollIntoView className="d-inline" selector="#latest"><a className="nav-link js-scroll-trigger">Latest</a></ScrollIntoView>
                        </li> */}
                        <li className="nav-item">
                            <ScrollIntoView className="d-inline" selector="#books"><a className="nav-link js-scroll-trigger">Books</a></ScrollIntoView>
                        </li>
                        <li className="nav-item">
                            <ScrollIntoView className="d-inline" selector="#podcast"><a className="nav-link js-scroll-trigger">Podcast</a></ScrollIntoView>
                        </li>
                        <li className="nav-item">
                            <ScrollIntoView className="d-inline" selector="#reviews"><a className="nav-link js-scroll-trigger">Reviews</a></ScrollIntoView>
                        </li>
                        <li className="nav-item">
                            <ScrollIntoView className="d-inline" selector="#television"><a className="nav-link js-scroll-trigger">Television</a></ScrollIntoView>
                        </li>
                        <li className="nav-item">
                            <ScrollIntoView className="d-inline" selector="#links"><a className="nav-link js-scroll-trigger">Links</a></ScrollIntoView>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
});

export default Nav;
import React from "react";
import {createRoot } from "react-dom/client";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import Error from "./pages/Error";
import PodcastEpisode from "./pages/PodcastEpisode";
import Bonus from "./pages/Bonus";
import { BrowserRouter, BrowserRouter as Router, Route, Routes} from "react-router-dom";
// import registerServiceWorker from "./registerServiceWorker";

const root = createRoot(document.getElementById("root"));
root.render(
(<BrowserRouter>
    <Routes>
        {/* <Route exact path="/Podcast/:episodeNumber" element={<PodcastEpisode/>} /> */}
        <Route exact path="/" element={<App/>} />
        <Route path="/bonus" element={<Bonus/>} />   
        <Route path="*" element={<Error/>} />
    </Routes>
</BrowserRouter>)
);
// registerServiceWorker();
import React from "react";
import Section from "../../components/Section";
import Coverflow from "coverflow-react-minor-tweaks";
import "./index.css"
import axios from "axios";


class Television extends React.Component {
    constructor(props) {
        super(props);
        this.state = { selectedShow: 5, showInfo: {} }
        this.televisionShows = [
            {url: "img/tv/BigWolfOnCampus.jpg", name: "Big Wolf On Campus", id: 1},
            {url: "img/tv/HopeAndGloria.jpg", name: "Hope & Gloria", id: 2},
            {url: "img/tv/LosLuchadores.jpg", name: "Los Luchadores", id: 3},
            {url: "img/tv/StarTrekVoyager.jpg", name: "Star Trek: Voyager", id: 4},
            {url: "img/tv/TheNewAddamsFamily.jpg", name: "The New Addams Family", id: 5},
            {url: "img/tv/TheFreshPrinceOfBelAir.jpg", name: "The Fresh Prince of Bel-Air", id: 6},
            {url: "img/tv//MisguidedAngels.jpg", name: "Misguided Angels", id: 7},
            {url: "img/tv/PacificBlue.jpg", name: "Pacific Blue", id: 8},
            {url: "img/tv/SilkStalkings.jpg", name: "Silk Stalkings", id: 9}
        ];
    }

    

    componentDidMount() {
        document.querySelector("#tvshows").addEventListener("click", event => {
            console.log("CLICKING", event.target.style.backgroundImage);
            let url = event.target.style.backgroundImage.replace('url("', '').replace('")', '')
            let show = this.televisionShows.find(show => show.url == url);
            if (show) {
                this.setState({selectedShow: show.id});
                this.getShowInfo(show.name)
            }
        })
        this.getShowInfo("The New Addams Family")
    }

    getShowInfo(name) {

        axios.get("/api/imdb/" + name)
        .then(({ data }) => {
            console.log(data)
            this.setState({showInfo: data})
        })
    }

    render() {
        const {
            props,
        } = this;





        return (
            <Section {...props}>
                <div className="container" style={{ height: "100vh" }}>
                    <div className="row"><h1 className="text-white-50 typewriter">TELEVISION</h1></div>
                    <div className="row">
                        <div className="col"><div id="tvshows" style={{marginTop:100, marginBottom: 100}}><Coverflow imagesArr={this.televisionShows.map(show => show.url)} height={200}/></div></div>
                    </div>
                    <div className="row">
                        <div className="col showDetails">
                            <div style={{}} id="tvshowdetail">
                                <h2><a href={this.state.showInfo.imdburl}>{this.state.showInfo.title}</a></h2>
                                <p>{this.state.showInfo.plot}</p>
                            </div>
                        </div>
                    </div>
                </div>
                
            </Section>
        );
    }
}

export default Television;